import React, { useEffect, useMemo, useState } from 'react';
import KrLeftSide from '../../../components/KrLeftside';
import KrDashPanCry from '../components/KrDashPanCry';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
	useAuthActions,
	useTradingActions,
} from '../../../hooks/useReduxActions';
import useApi from '../../../hooks/useApi';
import { Trade, TradeInput, TradeMode } from '../../../interfaces/trading';
import { BvTokens } from '../../../interfaces/Auth';
import tradingService from '../../../services/TradingService';
import { useForm } from '../../../hooks/useForm';
import { emptyValidator } from '../../../utils/validators/emptyValidator';
import { toast } from 'react-toastify';

interface GraphProps {
	symbol: string;
}

const Graph: React.FC<GraphProps> = ({ symbol }) => {
	return (
		<iframe
			id='tradingview_2847d'
			src={`https://s.tradingview.com/widgetembed/?frameElementId=tradingview_2847d&symbol=COINBASE%3A${symbol}&interval=D&range=1M&hidesidetoolbar=0&symboledit=1&saveimage=1&toolbarbg=f1f3f6&details=1&calendar=1&hotlist=1&studies=%5B%5D&theme=dark&style=1&timezone=Etc%2FUTC&withdateranges=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=marex-capital.us&utm_medium=widget&utm_campaign=chart&utm_term=COINBASE%3A${symbol}#%7B%22page-uri%22%3A%22marex-capital.us%2Fuser%2Fhome%22%7D`}
			style={{
				width: '100%',
				height: '100%',
				margin: '0 !important',
				padding: '0 !important',
			}}
			frameBorder='0'
			allowTransparency
			scrolling='no'
			allowFullScreen
		></iframe>
	);
};

const get_trades = (data: { tokens: BvTokens; mode: TradeMode }) =>
	tradingService.get_user_trades(data.tokens, data.mode);

const submit_trade = (data: { tokens: BvTokens; data: TradeInput }) =>
	tradingService.submit_trading(data.tokens, data.data);

export const QuickTrading: React.FC = () => {
	const [symbol, setSymbol] = useState('BCHBTC');

	const { tokens, user_data } = useAppSelector((state) => state.authReducer);

	const { trades } = useAppSelector((state) => state.tradingReducer);

	const { mode } = useAppSelector((state) => state.cachedReducer);

	const { setTrades, addTrade } = useTradingActions();
	const { updateUserData } = useAuthActions();

	const getTradeApiRequest = useApi<
		{ data: Trade[] },
		{ tokens: BvTokens; mode: TradeMode }
	>(get_trades);

	useEffect(() => {
		if (tokens) {
			getTradeApiRequest.request({ tokens, mode }).then((data) => {
				if (data) {
					setTrades(data.data);
				}
			});
		}
	}, [tokens, mode]);

	const tradingForm = useForm<Omit<TradeInput, 'trade_mode'>>(
		{
			amount: '',
			duration: '',
			mode: '',
			strike_rate: '',
			trading_type: 'Cryptocurrency',
			symbol: '',
		},
		{
			amount: emptyValidator,
			duration: emptyValidator,
			mode: emptyValidator,
			strike_rate: emptyValidator,
			trading_type: emptyValidator,
			symbol: emptyValidator,
		}
	);

	const handleChange: React.ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (e) => {
		tradingForm.onChange(
			e.target.name as keyof Omit<TradeInput, 'trade_mode'>,
			e.target.value
		);

		if (e.target.name === 'symbol') {
			setSymbol(e.target.value);
		}
	};

	const tradingApiRequest = useApi<
		{ status: boolean; message: string; data: Trade },
		{ tokens: BvTokens; data: TradeInput }
	>(submit_trade);

	useEffect(() => {
		if (!tradingForm.form.mode) tradingForm.onChange('mode', mode);
	}, [mode, tradingForm.form.mode]);

	const buyAmount = useMemo(() => {
		if (tradingForm.form.amount && tradingForm.form.strike_rate) {
			let buyPercent: string | number =
				tradingForm.form.strike_rate.split(' ')[0];
			buyPercent = buyPercent.substring(0, buyPercent.length - 1);
			buyPercent = parseInt(buyPercent);

			return ((tradingForm.form.amount * buyPercent) / 100).toFixed(2);
		}
	}, [tradingForm.form.amount, tradingForm.form.strike_rate]);

	const sellAmount = useMemo(() => {
		if (tradingForm.form.amount && tradingForm.form.strike_rate) {
			let sellPercent: string | number =
				tradingForm.form.strike_rate.split(' ')[2];
			sellPercent = sellPercent.substring(0, sellPercent.length - 1);
			sellPercent = parseInt(sellPercent);

			return ((tradingForm.form.amount * sellPercent) / 100).toFixed(2);
		}
	}, [tradingForm.form.amount, tradingForm.form.strike_rate]);

	useEffect(() => {
		if (tradingApiRequest.error) {
			toast(tradingApiRequest.error, { theme: 'colored', type: 'error' });
		}
	}, [tradingApiRequest.error]);

	const handleSubmit = async (type: 'Buy' | 'Sell') => {
		tradingForm.resetFormErrors();
		tradingApiRequest.reset();

		const valid = tradingForm.validate();

		if (valid && tokens) {
			const res = await tradingApiRequest.request({
				tokens,
				data: { ...tradingForm.form, trade_mode: type },
			});

			if (res && res.status) {
				toast(res.message, { theme: 'colored', type: 'success' });

				if (user_data) {
					const updateUser = { ...user_data };

					if (mode === 'demo') {
						updateUser.demo_balance = updateUser.demo_balance - res.data.amount;
					} else if (mode === 'real') {
						updateUser.balance = updateUser.balance - res.data.amount;
					}

					updateUserData(updateUser);
				}

				addTrade(res.data);

				tradingForm.reset();
			}
		}
	};

	return (
		<div className='bv-quicktrading-page'>
			<KrDashPanCry
				handleSubmit={handleSubmit}
				loading={tradingApiRequest.loading}
				form={tradingForm.form}
				handleChange={handleChange}
				buyAmount={buyAmount}
				sellAmount={sellAmount}
			/>

			<div className='d-flex flex-column flex-lg-row w-full'>
				<KrLeftSide />

				<div id='tradingview_2847d-wrapper' className='trading'>
					<div
						style={{
							width: '100%',
							height: '100%',
							background: 'transparent',
							padding: '0 !important',
						}}
					>
						<Graph symbol={symbol} />
					</div>
				</div>

				<div className='affan-features-wrap py-3'>
					<div className='container mb-2'>
						<div className='element-heading'>
							<h6>{mode.substring(0, 1).toUpperCase() + mode.substring(1)}</h6>
						</div>
					</div>

					<div className='container mb-4'>
						<div className='card'>
							<div className='card-body'>
								<div className='row'>
									<div className='col'>
										<div className='form-group'>
											<label className='col-form-label'>Category</label>
											<select
												name='trading_type'
												id='category'
												className='form-control select2 w-p100'
												value={tradingForm.form.trading_type}
												onChange={handleChange}
											>
												<option value='' selected disabled>
													Select
												</option>
												<option value='Cryptocurrency'>Cryptocurrency </option>
												<option value='Currencies'>Currencies</option>
											</select>
										</div>
									</div>

									<div className='col'>
										<div className='form-group'>
											<label className='col-form-label'>Symbol</label>
											<select
												name='symbol'
												id='showsymbols'
												className='form-control'
												value={tradingForm.form.symbol}
												onChange={handleChange}
											>
												<option value='' selected disabled>
													Select
												</option>
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='BCHBTC'>BCH/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='BCHEUR'>BCH/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='BCHGBP'>BCH/GBP</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='BTCEUR'>BTC/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='BTCGBP'>BTC/GBP</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='BTCUSD'>BTC/USD</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='EOSBTC'>EOS/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='EOSEUR'>EOS/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='EOSUSD'>EOS/USD</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='ETCBTC'>ETC/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='ETCEUR'>ETC/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='ETCGBP'>ETC/GBP</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='ETCUSD'>ETC/USD</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='ETHBTC'>ETH/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='ETHEUR'>ETH/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='ETHGBP'>ETH/GBP</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='ETHUSD'>ETH/USD</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='ETHUSDT'>ETH/USDT</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='LTCBTC'>LTC/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='LTCEUR'>LTC/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='LTCGBP'>LTC/GBP</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='LTCUSD'>LTC/USD</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='LTCUSDT'>LTC/USDT</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='MKRBTC'>MKR/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='PCPBTC'>PCP-BTC</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='REPBTC'>REP/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='REPUSD'>REP/USD</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='SHIBUSDT'>SHIB/USDT</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='TRXUSDT'>TRX/USDT</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='TXRGBP'>TXR/GBP</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='XLMBTC'>XLM/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='XLMEUR'>XLM/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='XLMUSD'>XLM/USD</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='XRPBTC'>XRP/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='XRPEUR'>XRP/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='XRPUSD'>XRP/USD</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='ZECBTC'>ZEC/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Cryptocurrency' && (
													<option value='ZRXBTC'>ZRX/BTC</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='ZRXEUR'>ZRX/EUR</option>
												)}
												{tradingForm.form.trading_type == 'Currencies' && (
													<option value='ZRXUSD'>ZRX/USD</option>
												)}
											</select>
										</div>
									</div>

									<div className='col'>
										<div className='form-group'>
											<label className='col-form-label'>Amount</label>
											<input
												className='form-control'
												type='number'
												id='amount_trade'
												value={tradingForm.form.amount}
												onChange={handleChange}
												name='amount'
												placeholder='Input Trade Amount'
												min={1}
												step={120}
											/>
										</div>
									</div>

									<div className='col'>
										<div className='form-group'>
											<label className='col-form-label'>Strike Rate</label>

											<br />

											<select
												name='strike_rate'
												className='form-control custom-select'
												id='strikerateused'
												value={tradingForm.form.strike_rate}
												onChange={handleChange}
											>
												<option value='' selected disabled>
													Select
												</option>
												<option value='75% & 3%'>75% &amp; 3%</option>
												<option value='12% & 5%'>12% &amp; 5%</option>
												<option value={'11% & 13%'}>11% &amp; 13%</option>
												<option value='1% & 18%'>1% &amp; 18%</option>
												<option value='1% & 61%'>1% &amp; 61%</option>
											</select>
										</div>
									</div>

									<div className='col'>
										<div className='form-group'>
											<label className='col-form-label' htmlFor='interval'>
												Interval
											</label>

											<select
												name='duration'
												id='interval'
												className='form-control'
												value={tradingForm.form.duration}
												onChange={handleChange}
											>
												<option value='' selected disabled>
													Select
												</option>
												<option value='60'>1 min</option>
												<option value='180'>3 mins</option>
												<option value='300'>5 mins</option>
												<option value='900'>15 mins</option>
												<option value='1800'>30 mins</option>
												<option value='3600'>1 hr</option>
												<option value='7200'>2 hr</option>
												<option value='86400'>1 day</option>
											</select>
										</div>
									</div>

									<div className='col'>
										<div className='form-group text-center mt-20'>
											{buyAmount && (
												<label className='text-success mb-2' id='buyamount'>
													${buyAmount}
												</label>
											)}

											<br />

											<button
												type='submit'
												id='buy'
												className='btn btn-success mb-2'
												disabled={tradingApiRequest.loading}
												onClick={() => handleSubmit('Buy')}
											>
												BUY<i className='ti ti-angle-up'></i>
											</button>

											<button
												type='submit'
												id='sell'
												className='btn btn-danger'
												disabled={tradingApiRequest.loading}
												onClick={() => handleSubmit('Sell')}
											>
												SELL<i className='ti ti-angle-down'></i>
											</button>

											<br />

											{sellAmount && (
												<label className='text-danger' id='sellamount'>
													${sellAmount}
												</label>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='container mb-2'>
						<div className='element-heading'>
							<h6>Transaction History</h6>
						</div>
					</div>

					<div className='container mb-4'>
						<div className='card'>
							<div className='card-body'>
								<div id='tradehistory' className='box-body no-padding'>
									<table className='table mb-0'>
										<thead>
											<tr>
												<td>Amount</td>
												<td>End Price</td>
												<td>Status</td>
											</tr>
										</thead>

										<tbody>
											{trades.length === 0 && (
												<tr>
													{' '}
													<td colSpan={8}>
														<div className='alert alert-danger text-center text-bold'>
															No Trade in History Yet!
														</div>
													</td>
												</tr>
											)}

											{trades.slice(0, 2).map((trade) => (
												<tr key={trade.trade_id}>
													<td>{trade.amount}</td>
													<td>
														{typeof trade.end_price !== 'number'
															? '-'
															: trade.end_price}
													</td>
													<td>
														{trade.status === 'Pending'
															? 'In Progress'
															: trade.status}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
